import { BudgetDocument, TranslateKeys } from '@og-shared/types';
import { AccountDocument, OgAccountType } from '../types/account';
import {
  ACCOUNT_ASSET_TYPES,
  ACCOUNT_LIABILITY_TYPES,
  INCOME_BUDGET_ID,
} from './consts';
import { exhaustiveCheck } from './utils';
import { currencyNumber } from './currency-utils';

export function getAvailableForBudget(
  accounts: Pick<
    AccountDocument,
    'include_in_available' | 'type' | 'current' | 'available'
  >[]
) {
  if (!accounts) return 0;
  let total = 0;
  accounts.map(account => {
    if (!account.include_in_available) return;
    if (account.type === 'credit' || account.type === 'loan') {
      total += account.current;
    } else {
      total += account.available;
    }
  });
  return Number(total.toFixed(2));
}

export function canIncludeInSpendingPlan(a: Pick<AccountDocument, 'type'>) {
  return a.type === 'credit' || a.type === 'depository';
}

export type AccountState = 'budget' | 'tracked' | 'excluded';

export function getAccountState(
  account: Pick<AccountDocument, 'deleted' | 'include_in_available'>
): AccountState {
  if (account.deleted) {
    return 'excluded';
  } else if (account.include_in_available) {
    return 'budget';
  } else if (!account.include_in_available) {
    return 'tracked';
  } else {
    return 'excluded';
  }
}

export function getAccountUpdateFromState(
  newState: AccountState
): Pick<AccountDocument, 'deleted' | 'include_in_available'> {
  switch (newState) {
    case 'budget': {
      return {
        include_in_available: true,
        deleted: false,
      };
    }
    case 'tracked': {
      return {
        include_in_available: false,
        deleted: false,
      };
    }
    case 'excluded': {
      return {
        include_in_available: false,
        deleted: true,
      };
    }
    default: {
      return exhaustiveCheck(newState);
    }
  }
}

export function getAccountDisplayNumber(
  account: Pick<AccountDocument, 'type' | 'current' | 'available'> | undefined
) {
  if (!account) return 0;
  return account.type === 'credit' ||
    account.type === 'loan' ||
    account.type === 'investment'
    ? account.current
    : account.available;
}

export function getAccountTypeTranslationKey(type: OgAccountType) {
  const accountTypes: { [key in OgAccountType]: TranslateKeys } = {
    depository: 'CHECKING_CASH',
    credit: 'CREDIT',
    loan: 'LOAN',
    investment: 'INVESTMENT_S',
    brokerage: 'BROKERAGE',
    other: 'OTHER',
    property: 'PROPERTY',
  };
  return accountTypes[type];
}

export function getNetWorthFromAccounts(accounts: AccountDocument[]) {
  const accountTypeTotals: { [key in OgAccountType]: number } = {
    brokerage: getTotalForAccountType(accounts, 'brokerage'),
    credit: getTotalForAccountType(accounts, 'credit'),
    depository: getTotalForAccountType(accounts, 'depository'),
    investment: getTotalForAccountType(accounts, 'investment'),
    loan: getTotalForAccountType(accounts, 'loan'),
    other: getTotalForAccountType(accounts, 'other'),
    property: getTotalForAccountType(accounts, 'property'),
  };
  const assets = ACCOUNT_ASSET_TYPES.reduce(
    (prev, curr) => accountTypeTotals[curr] + prev,
    0
  );
  const liabilities = ACCOUNT_LIABILITY_TYPES.reduce(
    (prev, curr) => accountTypeTotals[curr] + prev,
    0
  );
  return {
    assets,
    liabilities,
    netWorth: assets + liabilities,
    accountTypeTotals,
  };
}

function getTotalForAccountType(
  accounts: Pick<AccountDocument, 'type' | 'available' | 'current'>[],
  type: OgAccountType
) {
  return accounts.reduce(
    (prev, curr) =>
      prev + (curr.type === type ? getAccountDisplayNumber(curr) : 0),
    0
  );
}

export function getAccountsVsBudget(params: {
  budgets: BudgetDocument[];
  totalAccountsAvailable: number;
}) {
  const { budgets, totalAccountsAvailable } = params;
  const envelopeBudgets = budgets.filter(
    b => !b.deleted && b.budget_id !== INCOME_BUDGET_ID && b.is_envelope
  );
  const negativeBudgets = envelopeBudgets
    .filter(b => b.available < 0)
    .reduce((prev, curr) => prev + curr.available, 0);

  let inBudgets = envelopeBudgets
    .filter(b => b.available > 0)
    .reduce((prev, curr) => prev + curr.available, 0);

  const incomeAvailable = currencyNumber(
    totalAccountsAvailable - inBudgets + negativeBudgets
  );

  return {
    incomeAvailable,
    totalAccountsAvailable,
    inBudgets,
    negativeBudgets,
  }; // should be zero
}

export function calcTotalAvailable(
  accounts: Pick<
    AccountDocument,
    'include_in_available' | 'type' | 'current' | 'available'
  >[]
) {
  let total = 0;
  let checkingTotal = 0;
  let debtTotal = 0;
  accounts.map(account => {
    if (!account.include_in_available) return;
    if (account.type === 'credit' || account.type === 'loan') {
      total += account.current || 0;
      debtTotal += account.current || 0;
    } else {
      total += account.available || 0;
      checkingTotal += account.available || 0;
    }
  });
  return {
    total: currencyNumber(total),
    checkingTotal: currencyNumber(checkingTotal),
    debtTotal: currencyNumber(debtTotal),
  };
}
